import {Component, Input, OnInit} from '@angular/core';
import {AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-news-admin',
  templateUrl: './news-admin.component.html',
  styleUrls: ['./news-admin.component.css']
})
export class NewsAdminComponent implements OnInit {
  news: Observable<any[]>;
  itemsRef: AngularFireList<any>;

  editKey:string;
  editText:string;
  editUrl:string;
  newText:string;
  newUrl:string;
  updateMode: boolean = false;

  constructor(private db: AngularFireDatabase) {
    this.itemsRef = this.db.list('dbventvenu/news');


    this.news = this.itemsRef.snapshotChanges().pipe(map(changes =>
      changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
    ));
    console.log(this.news);

  }
  deleteItem(key: string) {
    this.itemsRef.remove(key);

  }
  addItem() {
    this.itemsRef.push({ new: this.newText, url: this.newUrl });
    this.newText = "";
    this.newUrl = "";
  }
  loadUpdate(newToUpdate:any){

    this.editKey = newToUpdate.key;
    this.editText = newToUpdate.new;
    this.editUrl = newToUpdate.url;
    this.updateMode = true;
}

  sendUpdate(){
    this.updateItem(this.editKey, this.editText, this.editUrl);
  }
  updateItem(key: string, updatedText: string, updatedUrl: string) {
    this.itemsRef.update(this.editKey, { new: this.editText, url: this.editUrl });
    this.updateMode = false;
  }
    ngOnInit(): void{}


}
