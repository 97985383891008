import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-admin-pro',
  templateUrl: './admin-pro.component.html',
  styleUrls: ['./admin-pro.component.css']
})
export class AdminProComponent implements OnInit {
  editKey:string;
  editNom:string;
  editUrl:string;
  updateMode: boolean = false;
  pdfs: Observable<any[]>;
  itemsRef: AngularFireList<any>;


  openFile(url) {
    window.open(url);
  }

  constructor(private db: AngularFireDatabase, public authService: AuthService) {
    this.itemsRef = this.db.list('dbventvenu/pdfs');


    this.pdfs = this.itemsRef.snapshotChanges().pipe(map(changes =>
      changes.map(c => ({key: c.payload.key, ...c.payload.val()}))
    ));

  }
  loadUpdate(newToUpdate:any){

    this.editKey = newToUpdate.key;
    this.editNom = newToUpdate.nom;
    this.editUrl = newToUpdate.url;
    this.updateMode = true;
  }

  sendUpdate(){
    this.updateItem(this.editKey, this.editNom, this.editUrl);
  }
  updateItem(key: string, updatedText: string, updatedUrl: string) {
    this.itemsRef.update(this.editKey, { new: this.editNom, url: this.editUrl });
    this.updateMode = false;
  }
    ngOnInit(): void {
  }

}
