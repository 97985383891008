<div class="container-fluid background" id="concerts">

  <div class="container">

    <div class="row flex-column align-items-center">
      <h3>Les dates</h3>
      <p style="color:white">Elles apparaissent dans l'ordre inverse coté visiteurs</p>

      <div class="container concert" *ngFor="let concert of concerts | async">

        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-9">

            <div class="row justify-content-start">
              <span class="date px-2">{{concert.date}} à {{concert.heure}}</span>
              <span class="lieu col-12 col-md-8">{{concert.lieu}}</span>
            </div>

            <div class="row justify-content-between">

              <div class="col-12">

                <span class="adresse">{{concert.adresse}}<br>{{concert.cp}} {{concert.ville}}</span>

              </div>
            </div>
          </div>
          <div class="row">

            <div class="billets d-flex align-items-center justify-content-end">
              <a href="{{concert.url}}" target="_blank" class="btn btn-success">Billetterie</a>
              <a (click)="loadUpdate(concert)" class="btn btn-warning mx-1">Editer</a>
              <button (click)="deleteItem(concert.key)" class="btn btn-danger"><strong>X</strong></button>
            </div>
          </div>

        </div>







      </div>
      <div class="newNew container">

        <div class="row flex-column justify-content-center align-items-center mt-3">

          <div class="form-group" style="width:100%">
            <textarea [(ngModel)]="newLieu" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="Le nom de la salle/du lieu"></textarea>
          </div>
          <div class="form-group" style="width:100%">
            <textarea [(ngModel)]="newAdresse" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="L'adresse de la salle'"></textarea>
          </div>
          <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="newCp" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="Le code postal"></textarea>
        </div>
          <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="newVille" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="C'est dans quel bled ? Miramas ?"></textarea>
        </div>

          <div class="form-group" style="width:100%">
            <textarea [(ngModel)]="newDate" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="La date du concert"></textarea>
          </div>
          <div class="form-group" style="width:100%">
            <textarea [(ngModel)]="newHeure" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="L'heure ou il faut se pointer"></textarea>
          </div>



          <div class="form-group" style="width:100%">

            <input type="text" [(ngModel)]="newUrl" class="inputNew" style="width:100%" name="newUrl" placeholder="Le lien billetterie ( avec https:// )" id="">
          </div>
          <div class="form-group">

            <button (click)="addItem()" class="btn btn-success">Poster</button>
          </div>


        </div>




      </div>



    </div>


  </div>

</div>
<div class="modal" [class.show]="updateMode" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edition</h5>
      </div>
      <div class="modal-body">
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editLieu" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="Le nom de la salle/du lieu"></textarea>
        </div>
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editAdresse" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="L'adresse de la salle'"></textarea>
        </div>
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editCp" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="Le code postal"></textarea>
        </div>
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editVille" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="C'est dans quel bled ? Miramas ?"></textarea>
        </div>

        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editDate" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="La date du concert"></textarea>
        </div>
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editHeure" name="editText" class="inputNew" style="width:100%" rows="1" placeholder="L'heure ou il faut se pointer"></textarea>
        </div>

        <div class="form-group" style="width:100%">

          <input type="text" [(ngModel)]="editUrl" class="inputNew" style="width:100%" name="editUrl" placeholder="Le lien billetterie ( avec https:// )" id="">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="updateMode=false" class="btn btn-secondary" data-bs-dismiss="modal">Laisse béton</button>
        <button type="button" (click)="sendUpdate()" class="btn btn-success">Change ce concert !</button>
      </div>
    </div>
  </div>
</div>
