<div class="container-fluid background" id="pro">

    <div class="row justify-content-center">
        <h2 class="text-center">Espace Pro</h2>
    </div>

    <div class="container trucsPro">

        <div class="col-10 col-md-3 pdf ficheTech">

            <button class="btn btn-success" (click)="openFile(pdfs[0].url)">Télécharger la Fiche Technique</button>


        </div>
        <div class="col-10 col-md-3 pdf planScene">

            <button (click)="openFile(pdfs[1].url)" class="btn btn-success">Télécharger le plan de scène</button>


        </div>


    </div>

</div>
