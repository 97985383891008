<div class="container-fluid background" id="videos">
    <div class="row justify-content-center">
        <h3>Musique</h3>
    </div>

    <div class="container">


        <swiper [thumbs]="{ swiper: thumbsSwiper }" [loop]=true [slidesPerView]="1" [spaceBetween]="50">
            <ng-template swiperSlide *ngFor="let video of videos">

                <youtube-player [videoId]="video.code"></youtube-player>

            </ng-template>

        </swiper>



        <swiper (swiper)="setThumbsSwiper($event)" [slidesPerView]="3" [spaceBetween]="10" [navigation]="false">
            <ng-template swiperSlide *ngFor="let video of videos">

                <img src="{{getThumbLink(video.code)}}">

            </ng-template>



        </swiper>






    </div>









</div>
