import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-admin-concerts',
  templateUrl: './admin-concerts.component.html',
  styleUrls: ['./admin-concerts.component.css']
})
export class AdminConcertsComponent implements OnInit {

  concerts: Observable<any[]>;
  itemsRef: AngularFireList<any>;
  editKey: string;
  editAdresse: string;
  editCp: string;
  editDate: string;
  editHeure: string;
  editLieu: string;
  editUrl: string;
  editVille: string;
  newAdresse: string;
  newCp: string;
  newDate: string;
  newHeure: string;
  newLieu: string;
  newUrl: string;
  newVille: string;
  updateMode = false;

  constructor(private db: AngularFireDatabase) {
    this.itemsRef = this.db.list('dbventvenu/concerts');


    this.concerts = this.itemsRef.snapshotChanges().pipe(map(changes =>
      changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
    ));
    console.log(this.concerts);

  }
  deleteItem(key: string) {
    this.itemsRef.remove(key);
  }
  addItem() {
    this.itemsRef.push({ adresse: this.newAdresse,
                         cp: this.newCp ,
                         date: this.newDate ,
                         heure: this.newHeure,
                         lieu: this.newLieu,
                         url: this.newUrl,
                         ville: this.newVille
    });
    this.newAdresse = '';
    this.newCp = '';
    this.newDate = '';
    this.newHeure = '';
    this.newLieu = '';
    this.newUrl = '';
    this.newVille = '';

  }
  loadUpdate(concertToUpdate: any){

    this.editKey = concertToUpdate.key;
    this.editAdresse = concertToUpdate.adresse;
    this.editCp = concertToUpdate.cp;
    this.editDate = concertToUpdate.date;
    this.editHeure = concertToUpdate.heure;
    this.editLieu = concertToUpdate.lieu;
    this.editUrl = concertToUpdate.url;
    this.editVille = concertToUpdate.ville;
    this.updateMode = true;
  }

  sendUpdate(){
    this.updateItem();
  }
  updateItem() {
    this.itemsRef.update(this.editKey,
      { adresse: this.editAdresse,
        cp: this.editCp,
        date: this.editDate,
        heure: this.editHeure,
        lieu: this.editLieu,
        url: this.editUrl,
        ville: this.editVille
      });
    this.updateMode = false;
  }

  ngOnInit(): void {
  }

}
