import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-concerts',
  templateUrl: './concerts.component.html',
  styleUrls: ['./concerts.component.css']
})
export class ConcertsComponent implements OnInit {

    @Input() concerts: any;



  constructor() { }

  ngOnInit(): void {
  }

}
