import { Component, OnInit, ElementRef } from '@angular/core';
import {faBandcamp, faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
faFB = faFacebookSquare;
faSC = faSoundcloud;
faYT = faYoutubeSquare;
faBC = faBandcamp;
faIG = faInstagram;



coucou(){
  let toggler = this.el.nativeElement.querySelector(".collapse");
  toggler.classList.remove('show');

}

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }


}
