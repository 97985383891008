<div class="container-fluid background" id="pro">

  <div class="row justify-content-center">
    <h2 class="text-center">Espace Pro</h2>
  </div>

  <div class="container trucsPro">

    <div class="row rowNews justify-content-center align-items-center">

      <div class="card col-10 col-md-8 mb-1" *ngFor="let pdf of pdfs | async">

        <div class="card-body d-flex flex-column justify-content-between align-items-center">
          <p class="card-text">{{pdf.nom}}</p><br>
           <a class="card-text px-2" href="{{pdf.url}}" target="_blank"><strong>Lien</strong></a><br>
          <div class="row px-2 justify-content-center">
            <a (click)="loadUpdate(pdf)" class="btn btn-warning mx-1">Editer</a>

          </div>


        </div>
      </div>


    </div>


  </div>
  <div class="row justify-content-center">

    <button (click)="authService.SignOut()" class="btn btn-danger btn-xl py-3 mb-5"><strong>Déconnexion</strong></button>
  </div>

</div>
<div class="modal" [class.show]="updateMode" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edition</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <h3>{{editNom}}</h3>
        </div>
        <div class="form-group" style="width:100%">

          <input type="url" [(ngModel)]="editUrl" class="inputNew" style="width:100%" name="newUrl" placeholder="Le lien (avec https://)" id="">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="updateMode=false" class="btn btn-secondary" data-bs-dismiss="modal">Laisse béton</button>
        <button type="button" (click)="sendUpdate()" class="btn btn-success">Change la news</button>
      </div>
    </div>
  </div>
</div>
