<div class="container-fluid background" id="toppy">

    <div class="row rowowo">

        <div class="mongolf col-10 col-md-6">
            <img src="assets/img/mongolfiere.png" width="100%">
        </div>

        <div class="mb-3 col-10 col-md-5 logoNews">

            <div class="logo col-10">
                <img class="imgLogo" src="assets/img/logo.png">
            </div>

        </div>


    </div>
    <div class="row rowNews justify-content-center align-items-center flex-column">

        <div class="card col-10 col-md-8 mb-1" *ngFor="let new of news | slice:0:3">

            <div class="card-body d-flex justify-content-between align-items-center">
                <p class="card-text">{{new.new}}</p>
                <a href="{{new.url}}" class="btn btn-primary boubou">Voir</a>
            </div>
        </div>


    </div>





</div>
