import { Component, OnInit } from '@angular/core';
import {faBandcamp, faFacebookSquare, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { HttpClient } from '@angular/common/http';
import {faCalendarPlus} from '@fortawesome/free-regular-svg-icons';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  faFB = faFacebookSquare;
  faSC = faSoundcloud;
  faYT = faYoutubeSquare;
  faBC = faBandcamp;
  faCP = faCalendarPlus;
  faIG = faInstagram;

  loginToggled:boolean = false;

  public nom :string='';
  public email:string='';
  public message:string='';

  rayponce:any;



  constructor(private http: HttpClient) {}
toggleLogin(){
    this.loginToggled = true;
}

leaveLogin(){
    this.loginToggled = false;
}

  sendy(){

    return this.http.post<any>('https://formsubmit.co/ajax/leventvenu@gmail.com',
    { name : this.nom, message: this.message, email:this.email  })
    .subscribe(data => {
      this.rayponce = data;

  });

  }

  ngOnInit(): void {
  }

    protected readonly faInstagram = faInstagram;
}
