<div class="panels">
    <div class="panel panel1" (click)="invertBen()" [ngClass]="ben ? 'open open-active' : ''">
        <p>Benoit Garnica</p>
        <div class="tof">
            <img class="imgTof" src="../../assets/img/bio/ben.png" alt="" srcset="">
        </div>
        <p>Benoit a reçu une formation de percussionniste classique au conservatoire d’Annecy puis de Paris. Multi-instrumentiste et compositeur, il se plait à travailler dans différents projets multi-artistiques.<br> Musicien intervenant au conservatoire
            de Lyon et Professeur de percussion, Benoit transmet sa passion depuis une dizaine d’année au sein d’écoles.<br> Féru de voyage, sa musique en est imprégnée. Il aborde le djembé au Mali, expérimente la derbouka en Turquie, il s’initie au shamisen
            Japonais et tombe sous le charme de l’harmonie et des rythmiques bulgares. En émerge une percussion riche et subtile, teintée d’ailleurs.</p>
    </div>
    <div class="panel panel2" (click)="invertSam()" [ngClass]="sam ? 'open open-active' : ''">
        <p>Samuel Liger</p>
        <div class="tof">
            <img class="imgTof" src="../../assets/img/bio/sam.png" alt="" srcset="">
        </div>
        <p>Il respire depuis son enfance l’air des campagnes Ardéchoises reculées et communautaires. Ce sont les excursions en pleine nature et les mois passés au clair de lune qui teintent sa voix d’une sonorité particulière.<br>     Travailleur social,
            animateur, cuisinier, pratiquant les plantes sauvages comestibles, vous le trouverez en train de coorganiser une tournée en roulottes, en ânes, à vélo afin de redonner leur lustre aux liens qui nous font communauté humaine.
            <br>      Etant toujours dans la recherche vocale, c’est naturellement que lui viennent mélodies et chants qu’il développe sur la route. La polyphonie étant l’essence de son travail, il partage aujourd’hui cela en menant des ateliers de chant
            choral.
        </p>
    </div>
    <div class="panel panel3" (click)="invertLu()" [ngClass]="lu ? 'open open-active' : ''">
        <p>Lucas Villon</p>
        <div class="tof">
            <img class="imgTof" src="../../assets/img/bio/lu.png" alt="" srcset="">
        </div>
        <p>Apprenant d’abord les bases de la guitare classique puis électrique, c’est en autodidacte qu’il apprend à chanter, jouer, composer, jusqu’à créer son premier groupe de rock.<br> Lorsqu’il rencontre « son âme-sœur de voix » Samuel, la grande aventure
            musicale débute. Leur travail sur la polyphonie vocale va permettre à Lucas d’étoffer son bagage musical.<br> Depuis 2014, Lucas est engagé par le Conservatoire de Lyon en tant que Musicien-Intervenant : il transmet sa passion dans les écoles
            lyonnaises auprès des enfants, des adultes et des personnes atteintes de handicap. Sa vision de la musique est tournée vers le partage.</p>
    </div>

</div>