<nav class="navbar navbar-expand-lg navbar-dark bg-light bg-custom">
    <a class="navbar-brand ml-2" pageScroll href="#toppy">

        <img src="assets/img/logo.png" class="logogo" alt="" srcset="">
    </a>
    <button class="navbar-toggler mr-2" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button>

    <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav mr-auto d-flex align-items-center">
            <li class="nav-item active">
                <a class="nav-link" pageScroll href="#videos" (click)="coucou()">Musique</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" pageScroll href="#prez" (click)="coucou()">Présentation</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" pageScroll href="#concerts" (click)="coucou()">Concerts</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" pageScroll href="#pro" (click)="coucou()">Pro</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" pageScroll href="#contact" (click)="coucou()">Contact</a>
            </li>
            <li class="nav-item mx-5">
                <a href="https://www.facebook.com/Le-Vent-Venu-179492049055831" target="_blank">
                    <fa-icon class="facebook" [icon]="faFB" size="3x"></fa-icon>
                </a>
                <a href="https://soundcloud.com/vent-venu" target="_blank" class="px-1">
                    <fa-icon [icon]="faSC" class="soundcloud" size="3x"></fa-icon>
                </a>
                <a href="https://www.youtube.com/channel/UCPr_zuCgWjSEMra3u6UbvQA" target="_blank"class="px-1">
                    <fa-icon [icon]="faYT" class="youtube" size="3x"></fa-icon>
                </a>
              <a href="https://leventvenu.bandcamp.com/releases" target="_blank">
                <fa-icon [icon]="faBC" class="soundcloud" size="3x"></fa-icon>
              </a>

              <a href="https://www.instagram.com/leventvenu/" target="_blank">
                <fa-icon [icon]="faIG" class="instagram px-1" size="3x"></fa-icon>
              </a>
            </li>


        </ul>

    </div>
</nav>


