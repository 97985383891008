import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  news: any;
  videos: any;
  concerts: any;

  constructor(db: AngularFireDatabase) {

    db.list('dbventvenu/videos').valueChanges().subscribe(tout => {
      this.videos = tout;

    });

    db.list('dbventvenu/news').valueChanges().subscribe(tout => {
      this.news = tout;


    });

    db.list('dbventvenu/concerts').valueChanges().subscribe(tout => {
      this.concerts = tout;

    });


  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
