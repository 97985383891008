import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bios',
  templateUrl: './bios.component.html',
  styleUrls: ['./bios.component.css']
})
export class BiosComponent implements OnInit {

  ben = false;
  sam = false;
  lu = false;
invertBen(){
    this.ben = !this.ben;
    if(this.ben == true){
      this.sam = false;
      this.lu = false;
    }
  }
invertSam(){
    this.sam = !this.sam;
    if(this.sam == true){
      this.lu = false;
      this.ben = false;
    }
  }
invertLu(){
  this.lu = !this.lu;
  if(this.lu == true){
    this.sam = false;
    this.ben = false;
  }
}

  constructor() { }

  ngOnInit(): void {
  }

}
