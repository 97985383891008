<div class="container-fluid background" id="concerts">

    <div class="container">

        <div class="row flex-column align-items-center">
            <h3>Nos prochaines dates</h3>

            <div class="container concert" *ngFor="let concert of concerts | slice:0:5">

                <div class="row align-items-center">
                    <div class="col-9">

                        <div class="row justify-content-start">
                            <span class="date px-2">{{concert.date}} à {{concert.heure}}</span>
                            <span class="lieu col-12 col-md-8">{{concert.lieu}}</span>
                        </div>

                        <div class="row justify-content-between">

                            <div class="col-12">

                                <span class="adresse">{{concert.adresse}}<br>{{concert.cp}} {{concert.ville}}</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-3">

                        <div class="billets d-flex align-items-center justify-content-end">
                            <a href="{{concert.url}}" target="_blank" class="btn btn-success">Billetterie</a>

                        </div>
                    </div>

                </div>







            </div>

            <button class="btn btn-success" data-toggle="modal" data-target="#momodadal">Voir tous les concerts</button>
            <div class="modal fade" id="momodadal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Tous nos concerts</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                        </div>
                        <div class="modal-body">
                            <div class="container concert" *ngFor="let concert of concerts">

                                <div class="row align-items-center">
                                    <div class="col-12">

                                        <div class="row justify-content-start">
                                            <span class="date blacky px-2">{{concert.date}} à {{concert.heure}}</span>
                                            <span class="lieu blacky col-12 col-md-8">{{concert.lieu}}</span>
                                        </div>

                                        <div class="row justify-content-between">

                                            <div class="col-12">

                                                <span class="adresse blacky">{{concert.adresse}}<br>{{concert.cp}} {{concert.ville}}</span>

                                            </div>
                                        </div>
                                    </div>


                                </div>







                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</div>