import { AngularFireDatabase } from '@angular/fire/database';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {
  news: any;
  videos: any;
  concerts: any;

  ficheTech = 'https://drive.google.com/file/d/13Ii_doIrKbzEvkRfxdPB-tGo8YuIwly9/view?usp=sharing';
  planScene = 'https://drive.google.com/file/d/1iChjcWbXIlshQRBK4krGqQsbLfLAf7wn/view?usp=sharing';
  constructor(db: AngularFireDatabase) {

     db.list('dbventvenu/videos').valueChanges().subscribe(tout => {
          this.videos = tout;

        });

     db.list('dbventvenu/news').valueChanges().subscribe(tout => {
          this.news = tout;


        });

     db.list('dbventvenu/concerts').valueChanges().subscribe(tout => {
          this.concerts = tout;

        });



   }

  ngOnInit(): void {
  }

}
