<div class="container-fluid background" id="contact">
    <div class="container">


        <form>
            <fieldset>
                <legend><strong>Nous contacter</strong></legend>

                <div class="form-group">
                    <label for="exampleTextarea">Votre Nom</label>
                    <textarea [(ngModel)]="nom" name="name" placeholder="Entrez votre nom" class="form-control" id="exampleTextarea" rows="1"></textarea>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Votre Email</label>
                    <input name="email" [(ngModel)]="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrez votre Email" style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
                </div>

                <div class="form-group">
                    <label for="exampleTextarea">Votre Message</label>
                    <textarea name="message" [(ngModel)]="message" placeholder="Entrez votre message" class="form-control" id="exampleTextarea" rows="3"></textarea>
                </div>
                <div class="bouton">
                    <button (click)="sendy()" class="btn btn-success" data-toggle="modal" data-target="#staticBackdrop">Envoyer</button>

                </div>

            </fieldset>
        </form>

        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content d-flex justify-content-center">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Message Envoyé !</h5>

                    </div>

                    <div class="modal-footer d-flex justify-content-center">
                        <button type="button" class="btn btn-success" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <div class="footer">

        <div class="col-12 col-md-8 trucsFooter">

            <span>Le Vent Venu - 2023</span>
            <a href="https://www.facebook.com/Le-Vent-Venu-179492049055831" target="_blank">
                <fa-icon class="facebook" [icon]="faFB" size="2x"></fa-icon>
            </a>
            <a href="https://soundcloud.com/vent-venu" target="_blank">
                <fa-icon class="soundcloud" [icon]="faSC" size="2x"></fa-icon>
            </a>
            <a href="https://www.youtube.com/channel/UCPr_zuCgWjSEMra3u6UbvQA" target="_blank">
                <fa-icon class="youtube" [icon]="faYT" size="2x"></fa-icon>
            </a>
          <a href="https://www.bandcamp.com" target="_blank">
            <fa-icon class="soundcloud" [icon]="faBC" size="2x"></fa-icon>
          </a>
          <a href="https://www.instagram.com/leventvenu/" target="_blank">
            <fa-icon [icon]="faIG" class="instagram px-1" size="2x"></fa-icon>
          </a>
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">
              Mentions Légales
            </button>
          <button style="border-radius:50%" class="btn btn-secondary" (click)="toggleLogin()" ><fa-icon class="" [icon]="faCP"></fa-icon></button>
            <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Mentions Légales</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                        </div>
                        <div class="modal-body">
                            Conditions générales d'utilisation En vigueur au _______________ Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par #175 Nom de la société
                            et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ». Les présentes CGU sont accessibles sur le site à la rubrique «CGU». Article 1 : Les mentions légales L’édition et la direction de la
                            publication du site leventvenu.com est assurée par Lucas VILLON, domicilié 6 rue alphonse Daudet. Numéro de téléphone est 0659523364 Adresse e-mail leventvenu@gmail.com. L'hébergeur du site leventvenu.com est la société OVH,
                            dont le siège social est situé au SAS, avec le numéro de téléphone : _______________. ARTICLE 2 : Accès au site Le site leventvenu.com permet à l'Utilisateur un accès gratuit aux services suivants : Le site internet propose
                            les services suivants : _______________ Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique,
                            logiciels, connexion Internet, etc.) sont à sa charge. ARTICLE 3 : Collecte des données Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément
                            à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Le site est déclaré à la CNIL sous le numéro _______________. En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978,
                            l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit : · par mail à l'adresse email leventvenu@gmail.com﻿ · via un formulaire
                            de contact ; ARTICLE 4 : Propriété intellectuelle Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement
                            par le droit d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement
                            privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite. Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant
                            du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle. Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur
                            qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source. ARTICLE 5 : Responsabilité Les sources des informations diffusées sur le site leventvenu.com sont réputées fiables mais le site ne garantit
                            pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site leventvenu.com ne
                            peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information
                            contenue dans ce site. Le site leventvenu.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au
                            téléchargement provenant de ce site. La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers. ARTICLE 6 : Liens hypertextes Des liens hypertextes peuvent être
                            présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site leventvenu.com. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun
                            cas, être responsable de leur contenu. ARTICLE 7 : Cookies L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Les cookies sont de petits
                            fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site #173 Adresse du site.... Les cookies ne contiennent pas d’information personnelle
                            et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.
                            L’information contenue dans les cookies est utilisée pour améliorer le site #173 Adresse du site.... En naviguant sur le site, L’Utilisateur les accepte. L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres
                            figurant au sein de son logiciel de navigation. ARTICLE 8 : Droit applicable et juridiction compétente La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les
                            parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1. CGU réalisées
                            sur http://legalplace.fr/

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  <div class="modal" [class.show]="loginToggled" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
      <div class="modal-content">
        <div class="modal-header row justify-content-around align-items-center">
          <h5 class="modal-title" id="exampleModalLabel"><strong>Connexion</strong></h5>
          <button (click)="leaveLogin()" class="btn btn-danger btn-lg"><strong>X</strong></button>
        </div>
        <div class="modal-body">

          <app-signin></app-signin>

      </div>
    </div>
  </div>
</div>
