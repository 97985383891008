<div class="container-fluid background" id="videos">
  <div class="row justify-content-center">
    <h3>Musique</h3>
  </div>

  <div class="container">


    <div class="row rowNews justify-content-center align-items-center flex-column">

      <div class="card col-10 col-md-8 mb-1" *ngFor="let video of videos | async">

        <div class="card-body d-flex flex-column justify-content-center align-items-center">
          <div class="row px-2 justify-content-around align-items-center">
            <p class="card-text mx-2"><strong>{{video.nom}}</strong></p>
            <img src="https://img.youtube.com/vi/{{video.code}}/2.jpg" style="height:50px" class="mx-2">

          </div>

          <div class="row px-2 justify-content-center mt-2">


            <a (click)="loadUpdate(video)" class="btn btn-warning mx-1">Editer</a>
            <button (click)="deleteItem(video.key)" class="btn btn-danger"><strong>X</strong></button>

          </div>


        </div>
      </div>


    </div>
    <div class="newNew container">

      <div class="row flex-column justify-content-center align-items-center mt-3">

        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="newNom" name="newText" class="inputNew" style="width:100%" rows="1" placeholder="Le nom de ta vidéo"></textarea>
        </div>
        <div class="form-group" style="width:100%">

          <input type="text" [(ngModel)]="newCode" class="inputNew" style="width:100%" name="newUrl" placeholder="Le code youtube ( exemple: FpToCzPb5AU )" id="">
        </div>
        <div class="form-group">

          <button (click)="addItem()" class="btn btn-success">Poster</button>
        </div>


      </div>




    </div>





  </div>









</div>
<div class="modal" [class.show]="updateMode" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edition</h5>
      </div>
      <div class="modal-body">
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editVideo" class="inputNew" style="width:100%" rows="3" placeholder="Ta news"></textarea>
        </div>
        <div class="form-group" style="width:100%">

          <input type="url" [(ngModel)]="editCode" class="inputNew" style="width:100%" name="newUrl" placeholder="Le lien (avec https://)" id="">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="updateMode=false" class="btn btn-secondary" data-bs-dismiss="modal">Laisse béton</button>
        <button type="button" (click)="sendUpdate()" class="btn btn-success">Change la vidéo</button>
      </div>
    </div>
  </div>
</div>
