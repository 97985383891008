import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-videos-admin',
  templateUrl: './videos-admin.component.html',
  styleUrls: ['./videos-admin.component.css']
})
export class VideosAdminComponent implements OnInit {
  videos: Observable<any[]>;
  itemsRef: AngularFireList<any>;
  editKey:string;
  editVideo:string;
  editCode:string;
  newNom:string;
  newCode:string
  updateMode: boolean = false;

  constructor(private db: AngularFireDatabase) {
    this.itemsRef = this.db.list('dbventvenu/videos');


    this.videos = this.itemsRef.snapshotChanges().pipe(map(changes =>
      changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
    ));
    console.log(this.videos);

  }
  deleteItem(key: string) {
    this.itemsRef.remove(key);
  }
  addItem() {
    this.itemsRef.push({ nom: this.newNom, code: this.newCode });
    this.newNom = "";
    this.newCode = "";
  }
  loadUpdate(newToUpdate:any){

    this.editKey = newToUpdate.key;
    this.editVideo = newToUpdate.nom;
    this.editCode = newToUpdate.code;
    this.updateMode = true;
  }

  sendUpdate(){
    this.updateItem(this.editKey, this.editVideo, this.editCode);
  }
  updateItem(key: string, updatedText: string, updatedUrl: string) {
    this.itemsRef.update(this.editKey, { nom: this.editVideo, code: this.editCode });
    this.updateMode = false;
  }
  ngOnInit(): void {
  }

}
