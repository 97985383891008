
<div class="container-fluid background" id="toppy">

  <div class="row rowowo">

    <div class="mongolf col-10 col-md-6">
      <img src="assets/img/mongolfiere.png" width="100%">
    </div>

    <div class="mb-3 col-10 col-md-5 logoNews">

      <div class="logo col-10">
        <img class="imgLogo" src="assets/img/logo.png">
      </div>

    </div>


  </div>
  <div class="row rowNews justify-content-center align-items-center flex-column">

    <div class="card col-10 col-md-8 mb-1" *ngFor="let new of news | async">

      <div class="card-body d-flex justify-content-between align-items-center">
        <p class="card-text">{{new.new}}</p>
       <div class="row px-2 justify-content-center">

         <a href="{{new.url}}" class="btn btn-primary">Voir</a>
         <a (click)="loadUpdate(new)" class="btn btn-warning mx-1">Editer</a>
         <button (click)="deleteItem(new.key)" class="btn btn-danger"><strong>X</strong></button>

       </div>


      </div>
    </div>


  </div>
  <div class="newNew container">

    <div class="row flex-column justify-content-center align-items-center mt-3">

      <div class="form-group" style="width:100%">
        <textarea [(ngModel)]="newText" name="newText" class="inputNew" style="width:100%" rows="3" placeholder="Ta news"></textarea>
      </div>
      <div class="form-group" style="width:100%">

        <input type="url" [(ngModel)]="newUrl" class="inputNew" style="width:100%" name="newUrl" placeholder="Le lien (avec https://)" id="">
      </div>
      <div class="form-group">

        <button (click)="addItem()" class="btn btn-success">Poster</button>
      </div>


    </div>




  </div>




</div>
<div class="modal" [class.show]="updateMode" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edition</h5>
      </div>
      <div class="modal-body">
        <div class="form-group" style="width:100%">
          <textarea [(ngModel)]="editText" class="inputNew" style="width:100%" rows="3" placeholder="Ta news"></textarea>
        </div>
        <div class="form-group" style="width:100%">

          <input type="url" [(ngModel)]="editUrl" class="inputNew" style="width:100%" name="newUrl" placeholder="Le lien (avec https://)" id="">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="updateMode=false" class="btn btn-secondary" data-bs-dismiss="modal">Laisse béton</button>
        <button type="button" (click)="sendUpdate()" class="btn btn-success">Change la news</button>
      </div>
    </div>
  </div>
</div>
