import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore ,{
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Thumbs
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs]);
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {


  thumbs: any;

  thumbsSwiper: any;
  setThumbsSwiper(swiper) {
    this.thumbsSwiper = swiper;
  }

 @Input() videos :any
  videosimple = [
    {nom : 'etoiles', url:"FpToCzPb5AU"},
    {nom : 'la roue', url:"hDGIoVrukRY"},
    {nom : 'fly',     url:"SQ7Z2JNWUvo"}
  ]



    getThumbLink(videoId){
      let avant = "https://img.youtube.com/vi/";
      let apres = "/2.jpg";
      let thumbUrl = avant+videoId+apres;

      return thumbUrl;

    }

  constructor() {
     }

  ngOnInit(): void {

  }



}
