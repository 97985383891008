<div class="row flex-column justify-content-center align-items-center">

  <div class="form-group">
    <input type="text" class="form-control" placeholder="T'es qui ?" #userName required>
  </div>

  <div class="form-group">
    <input type="password" class="form-control" placeholder="T'as un mot de passe aussi ?" #userPassword required>
  </div>

  <!-- Calling SignIn Api from AuthService -->
  <div class="form-group">
    <button type="button" class="btn btn-success" (click)="authService.SignIn(userName.value, userPassword.value)">Connexion</button>
  </div>


</div>

