// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyCS6hxX_2FQT_4JafGqzv-ChjSsfpCdEjQ",
      authDomain: "leventvenu-3f7a3.firebaseapp.com",
      databaseURL: "https://leventvenu-3f7a3-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "leventvenu-3f7a3",
      storageBucket: "leventvenu-3f7a3.appspot.com",
      messagingSenderId: "715051477263",
      appId: "1:715051477263:web:2a6494ed3d3c1ae4d2f67a",
      measurementId: "G-H20MP569WZ"}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
