import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { VideosComponent } from './videos/videos.component';
import {SwiperModule} from 'swiper/angular';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { BiosComponent } from './bios/bios.component';
import { PresentationComponent } from './presentation/presentation.component';
import { ConcertsComponent } from './concerts/concerts.component';
import { ProComponent } from './pro/pro.component';
import { ContactComponent } from './contact/contact.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AdminComponent } from './admin/admin.component';
import { PublicComponent } from './public/public.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database'
import {AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { NewsAdminComponent } from './news-admin/news-admin.component';
import { VideosAdminComponent } from './videos-admin/videos-admin.component';
import { AdminConcertsComponent } from './admin-concerts/admin-concerts.component';
import { AdminProComponent } from './admin-pro/admin-pro.component';
import { SigninComponent } from './signin/signin.component';
import {AuthService} from './auth.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    VideosComponent,
    BiosComponent,
    PresentationComponent,
    ConcertsComponent,
    ProComponent,
    ContactComponent,
    AdminComponent,
    PublicComponent,
    NewsAdminComponent,
    VideosAdminComponent,
    AdminConcertsComponent,
    AdminProComponent,
    SigninComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    SwiperModule,
    YouTubePlayerModule,
    NgxPageScrollModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
