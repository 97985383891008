<div class="container-fluid background" id="prez">

    <h3>Le Vent Venu</h3>
    <div class="row justify-content-center align-items-center">

        <div class="container">

            <p class="textPrez">De tout temps les ménestrels, les chamans, les griots nous ont conté notre histoire, nos évolutions, nos émotions, par la musique : le monde de l’invisible. Portés par ces traditions, ce trio d’amis s’engage dans la voix. Ils chantent pour
                être ensemble, leur force est de transmettre au public l’émotion et les sonorités qui les animent. <br><br>Le Vent Venu dévoile une musique atmosphérique aux multiples facettes. <br>Tirant de la chanson poétique ses inspirations premières,
                c’est en enfants de l’Acoustie que ce trio s’inspire de tous les styles musicaux, plus particulièrement du rock, du reggae, ou encore du hip-hop. <br><br>Ils ont grandi autant avec la chanson française, qu’avec des groupes anglophones
                (Radiohead, System of a Down, pour ne citer qu’eux), c’est donc une évidence qu’ils chantent et écrivent en anglais comme en français. On retrouve dans leur interprétation l’influence de polyphonies vocales du monde entier, cueillies au
                gré des voyages,des expériences de chacun. <br><br>Le concert commence, et c’est le début d’un voyage. <br><br>L’accent vibrant des polyphonies vocales se mêle à la guitare folk aérienne. Un souffle musical s’installe, rythmé par les percussions
                délicates et précises.<br><br>Leurs compositions, comme les reprises choisies se font tour à tour planantes, électriques, mélancoliques, dansantes, poétiques, chaleureuses. Pourtant, le Vent Venu garde une sincérité et un style qui lui
                est propre.
            </p>

        </div>
    </div>

</div>
