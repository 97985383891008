import {Component, Input, OnInit} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';


@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.css']
})
export class ProComponent implements OnInit {

 pdfs: any;

  ficheTech: any;
  planScene: any;

  openFile(url) {
    window.open(url);
}

  constructor( db: AngularFireDatabase


  ) {
    db.list('dbventvenu/pdfs').valueChanges().subscribe(tout => {
      this.pdfs = tout;
    });


  }
  ngOnInit(): void {
  }

}
